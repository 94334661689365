const baseUrl = "/log/mobilebingding";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    mobile:string,
    subscriberCode:string,
    instanceCode:string,
    beginSubsTime: string;
    endSubsTime: string;
}

//定义表格的属性
export interface TableDataType {
    index: number;
    mobile: string;
    subscriberCode: string;
    instanceCode: string;
    subsTime: string;
    status: string;
    statusDesc: string;
}



export  {baseUrl};

