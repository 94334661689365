const baseUrl = "/cus/contacts";
export interface PageParam {
}

//定义表格的属性
export interface TableDataType {
    id: number;
    name: string;
    number: string;
    thirdpartPlatform: string;
    thirdpartQrcode: string;
    thirdpartAccount: string;
    reorder: number;
}



export  {baseUrl};