import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/sys/logMobilebingding";
import commonApi from "../commonApi";

const logMobilebingdingApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.mobile) {
                url += 'mobile=' + encodeURIComponent(body.mobile) + "&";
            }
            if (body.subscriberCode) {
                url += 'subscriberCode=' + encodeURIComponent(body.subscriberCode) + "&";
            }
            if (body.instanceCode) {
                url += 'instanceCode=' + encodeURIComponent(body.instanceCode) + "&";
            }
            if (body.beginSubsTime) {
                url += 'beginSubsTime=' + body.beginSubsTime + "&";
            }
            if (body.endSubsTime) {
                url += 'endSubsTime=' + body.endSubsTime + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    getMenusOptions() {
        let url = baseUrl;
        url += "/menus"
        return request.get<OptionRes[]>(url)
    },
    getOperationTypeOption() {
        let url = baseUrl;
        url += "/types"
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"运营后台操作日志列表",body)
    },
}

export default logMobilebingdingApi



