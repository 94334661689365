import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import Details from "../../public/details/details";
import {TableDataType} from "../../../../apis/types/sys/logMobilebingding";
import userApi from "../../../../apis/sys/user";
import logMobilebingdingApi from "../../../../apis/sys/logMobilebingding";
import dayjs from "dayjs";
import logMobilebingding from "../../../../apis/sys/logMobilebingding";


const {RangePicker} = DatePicker;

const LogMobilebingdingContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [userOption, setUserOption] = useState<OptionRes[]>([])
    const [menuOption, setMenuOption] = useState<OptionRes[]>([])
    const [operationTypeOption, setOperationTypeOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginSubsTime: body.beginSubsTime ? dayjs(body.beginSubsTime).format('YYYY-MM-DD') : null,
            endSubsTime: body.endSubsTime ? dayjs(body.endSubsTime).format('YYYY-MM-DD') : null,
        }
        console.log("queryBody is", body)
        let res = logMobilebingdingApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }

        })
    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            ellipsis: true,
            width: 1
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            width: 1
        },
        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
            ellipsis: true,
            width: 1
        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            ellipsis: true,
            width: 1
        },
        {
            title: '绑定时间',
            dataIndex: 'subsTime',
            key: 'subsTime',
            ellipsis: true,
            width: 1
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
            width: 1
        },

    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item name="mobile" label="手机号码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="subscriberCode" label="会员编码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="instanceCode" label="云手机编码">
                        <Input/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="subsTime" label="绑定时间">
                        <RangePicker
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginSubsTime", null);
                                    form.setFieldValue("endSubsTime", null);
                                } else {
                                    if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginSubsTime", date[0]);
                                            form.setFieldValue("endSubsTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }
                            }}></RangePicker>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="beginSubsTime" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}} name="endSubsTime" hidden={true}></Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                logMobilebingding.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default LogMobilebingdingContent;