import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/mkt/redeemCode/redeemCodeVo";
import commonApi from "../../commonApi";

function isValidTimeFormat(value) {
    // Assuming a simple check for the 'YYYY-MM-DD HH:mm:ss' format
    const timeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return timeRegex.test(value);
}

const redeemCodeApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        for (const key in body) {
            console.log("key:" +  key + ":" + body[key])
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                let valueToEncode = body[key];

                queryParams.set(key, valueToEncode);
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);
    },

    createRedeemCode(body: any){
        let url = baseUrl + "/createRedeemCode";
        return request.post(url, body);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"激活码列表",body)
    },
    batchEnable(body: any) {
        let url = baseUrl + '/enable';
        return request.post(url, body);
    },

    batchDisable(body: any) {
        let url = baseUrl + '/disable';
        return request.post(url, body);
    },


}

export default redeemCodeApi;



