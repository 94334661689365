import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form, Image,
    Input, InputNumber,
    message, Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import Details from "../../public/details/details";
import {TableDataType} from "../../../../apis/types/cus/csConfig";

import csConfigApi from "../../../../apis/cus/csConfig";
import dayjs from "dayjs";
import ContactFrom from "./contactFrom";
import propertiesApi from "../../../../apis/sys/properties";
import {imgIp} from "../../../../components/constantConfig";

const CsConfigContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();

        propertiesApi.optinos('DM_Thirdpart_Platform').then((res) => {
            if (res) {
                setThirdpartPlatformOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Cus_Type').then((res) => {
            if (res) {
                console.log("get properties DM_Cus_Type: "+res.data);
                setConfigOption(res.data)
            }
        })


    }, []);

    const [configForm] = Form.useForm();

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [open, setOpen] = useState(false)
    const [openConfig, setOpenConfig] = useState(false);
    const [total, setTotal] = useState<number>()
    const [addType, setAddType] = useState<boolean>(false)
    const [model, setModel] = useState('')
    const [thirdpartPlatformOption, setThirdpartPlatformOption] = useState<OptionRes[]>([])
    const [configOption, setConfigOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            operationStartTime: body.operationStartTime ? dayjs(body.operationStartTime).format('YYYY-MM-DD HH:mm:ss') : null,
            operationEndTime: body.operationEndTime ? dayjs(body.operationEndTime).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        let res = csConfigApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([])
                setIdList([])
            }

        })
    }

    //处理删除
    const handleDelete = () => {
        csConfigApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
                message.success('删除成功');
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [

        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setAddType(false);
                    setModel('edit')
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis: true,
            width: 1
        },
        {
            title: '客服名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 1
        },
        {
            title: '客服渠道',
            dataIndex: 'thirdpartPlatform',
            key: 'thirdpartPlatform',
            ellipsis: true,
            width: 1
        },
        {
            title: '客服号码',
            dataIndex: 'number',
            key: 'number',
            ellipsis: true,
            width: 1
        },
        // {
        //     title: '客服二维码',
        //     dataIndex: 'thirdpartQrcode',
        //     key: 'thirdpartQrcode',
        //     ellipsis: true,
        //     width: 3
        // },
        {
            title: '客服二维码',
            dataIndex: 'thirdpartQrcode',
            key: 'thirdpartQrcode',
            render: (thirdpartQrcode: string) => {
                const url = imgIp + thirdpartQrcode;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 1
        },
        {
            title: '权重',
            dataIndex: 'reorder',
            key: 'reorder',
            ellipsis: true,
            width: 1
        },

    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    function confirm() {
        if (idList.length === 0) {
            message.error('删除失败，请选择需要删除的数据');
        } else {
            handleDelete();
        }
    }

    function cancel() {
        message.error('取消删除');
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setAddType(true);
                    setRowData({} as TableDataType)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <ContactFrom
                type={addType}
                refresh={() => {
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                setOpen(false)
                setRowData({} as TableDataType)
                }}
                model={model}
                thirdpartPlatformOption={thirdpartPlatformOption}
            >
            </ContactFrom>


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>


            <Modal
                cancelText="取消"
                okText="确认"
                title="客服交互方式" open={openConfig} onOk={() => {
                let body = configForm.getFieldsValue();
                body = {
                    type: body.type,
                }
                csConfigApi.setConfig(body).then((res) => {
                    if (res) {
                        setOpenConfig(false)
                        configForm.resetFields(res.data);
                        // handleQuery();
                    }
                })


            }} onCancel={() => {
                setOpenConfig(false)
            }}

            >
                <Form
                    form={configForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="type" label="客服交互方式" rules={[{required: true, message: "客服交互方式必填"}]}>
                        <Select
                            style={{width: 200}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={configOption}
                        />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}


export default CsConfigContent;