import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Image, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Upload, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/cus/csConfig";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import csApi from "../../../../apis/cus/csConfig";
import {filterOption} from "../../../../apis/common";
import {UploadOutlined} from "@ant-design/icons";
import {domain, imgIp} from "../../../../components/constantConfig";
import {getToken} from "../../../../utils";
import {UploadFile} from "antd/es/upload/interface";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ContactFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    model: String;
    thirdpartPlatformOption: OptionRes[];
}


const ContactFrom: React.FC<ContactFromProps> = ({
                                               type,
                                               refresh,
                                               record,
                                               open,
                                               closeOpen,
                                               model,
                                                  thirdpartPlatformOption
                                           }) => {
    const [form] = Form.useForm();
    const [accountId, setAccountId] = useState<number>(0)
    //客服二维码
    const [thirdpartQrcode, setThirdpartQrcode]  = useState(record.thirdpartQrcode)
    const [thirdpartQrcodeList, setThirdpartQrcodeList] = useState<UploadFile[]>([])
    const [oldThirdpartQrcode, setOldThirdpartQrcode] = useState(record.thirdpartQrcode);

    const thirdpartQrcodeprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: thirdpartQrcodeList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            // setProductIconList([...productIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setThirdpartQrcode(urlRes)
                    setThirdpartQrcodeList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setThirdpartQrcodeList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setThirdpartQrcodeList(model === 'create' ? "" : oldThirdpartQrcode)
        },

    };

    // 编辑的时候回显数据
    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        console.log("useEffecttt", record)
        form.setFieldsValue({})
        form.resetFields()
        console.log("form",form.getFieldsValue())
        if (open&&record) {
            form.setFieldsValue({
                ...record
            });
        }
        setAccountId(record.id);
    }, [open]);

    useEffect(() => {
        setOldThirdpartQrcode("")
        setThirdpartQrcode("")
        // 在组件挂载时发送请求
        if (accountId) {
            console.log("record", record)
            setOldThirdpartQrcode(record.thirdpartQrcode)
            setThirdpartQrcode(record.thirdpartQrcode)
        }

    }, [accountId]); // 当 productId 变化时触发

    //处理新增方法
    const handleCreate = () => {
        const values = form.getFieldsValue();
        values.thirdpartQrcode = thirdpartQrcode;
        csApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        setThirdpartQrcode("")
        setThirdpartQrcodeList([])
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        form.setFieldValue("thirdpartQrcode",thirdpartQrcode);
        console.log("form.values="+form.getFieldsValue());
        const values = form.getFieldsValue();
        values.thirdpartQrcode = thirdpartQrcode;
        let valueJSOn = JSON.stringify(values);
        csApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {

        form.setFieldValue("thirdpartQrcode",thirdpartQrcode);
        console.log("form.values="+form.getFieldsValue());
        form.validateFields().then(() => {
            const values = form.getFieldsValue();

            if (values.id == null) {
                // 做新增的逻辑
                console.log("do create.")
                handleCreate();
            } else {
                // 做修改的逻辑
                console.log("do update.")
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title={type ? "新增客服" : "编辑客服"}
            open={open}
            width={600}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            okText={"保存"}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // initialValues={record}
                onFinish={handleOK}
            >

                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="name" label="客服名称"
                           rules={[{required: true, message: "客服名称不能为空"}, {max: 5, message: "客服名称不能超过5个字符"}]}>
                    <Input placeholder="不超过5个字符" style={{width: 200}}></Input>
                </Form.Item>
                <Form.Item name="thirdpartPlatform" label="客服渠道" rules={[{required: true, message: "客服渠道必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={thirdpartPlatformOption}
                    />
                </Form.Item>

                <Form.Item name="number" label="客服号码"
                           rules={[{required: true, message: "客服号码不能为空"}, {max: 20, message: "客服号码不能超过20个字符"}]}>
                    <Input placeholder="请输入QQ号/微信号" style={{width: 200}}></Input>
                </Form.Item>


                {/*<Form.Item name="thirdpartAccount" label="第三方账号"*/}
                {/*           rules={[{required: true, message: "第三方账号不能为空"}, {max: 20, message: "第三方账号不能超过20个字符"}]}>*/}
                {/*    <Input/>*/}
                {/*</Form.Item>*/}



                <Form.Item name="thirdpartQrcodeUplod" label="客服二维码上传">
                    <Upload {...thirdpartQrcodeprops}>
                        <Button style={{width: 200}} icon={<UploadOutlined/>}>点击上传</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="thirdpartQrcode" label="客服二维码" rules={[{required: true, message: "客服二维码必填"}]}>
                    <Image src={imgIp + thirdpartQrcode} width={100}/>
                </Form.Item>

                <Form.Item name="reorder" label="权重" rules={[{ required: true , message: "权重必填"}, {type: 'number', min: 0}]}>
                    <InputNumber></InputNumber>
                </Form.Item>



                {/*<div style={{textAlign: "center"}}>*/}
                {/*    <Form.Item style={{display: "inline-block"}}>*/}
                {/*        <Button type="primary" htmlType="submit">*/}
                {/*            提交*/}
                {/*        </Button>*/}
                {/*    </Form.Item>*/}
                {/*</div>*/}
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ContactFrom;



